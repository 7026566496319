import axios from 'axios';
import React, {useState, useEffect}  from 'react';
import { Nav, Navbar, NavItem, NavDropdown, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {NavLink, useHistory, Link, useLocation} from "react-router-dom";

function Header(props) {
	const history = useHistory()
	const [user, setUser] = useState(false);
	const [name, setName] = useState('');
	const [open, setOpen] = useState(false);
    const [notify, setNotify] = useState([]);
	useEffect(()=>{
        const base_url = process.env.REACT_APP_SERVER;
		const interval = setInterval(()=>{
			const isLoggedIn = localStorage.getItem("africanStack")
			if(isLoggedIn){
				setUser(true)
                setName(JSON.parse(localStorage.getItem("africanStack")).name)
			}
			else{
				setUser(false)
                setName('')
			}
		}, 500)
        axios.get(`${base_url}/api/notification`).then(res=>{
            // console.log(res.data)
            setNotify(res.data.payload)
        }).catch(err=>{
            console.log(err)
        })
        
	}, [])

    const notify_list = notify.slice(0,7).map(n=>{
        return(
            <li key= {n._id} className='mb-2' >
                <div className="cs-notification_item">
                    <div className="cs-notification_right">
                        <p>{n.content}</p>
                    </div>
                </div>
            </li>
        )
    })
    const reloadPage = () =>{
        history.push('/dashboard');
        window.location.reload()
    }
	const closeNav =()=>{
        // var myNav = document.getElementById('responsive-navbar-nav');
        document.getElementById("responsive-navbar-nav").style.height = "0%"
        setOpen(false)
    }
    const openNav =()=>{
        document.getElementById("responsive-navbar-nav").style.height = "100%"
        setOpen(!open)
    }

	const handleLogout = () =>{
        localStorage.clear('africanStack')
        history.push('/')
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" style={{width: '300px'}} {...props}>
          <div className="cs-toggle_body text-left">
            <h5 className="cs-notification_title">Notifications {notify.length}</h5>
            <ul className="cs-notification_list list-unstyled">
                {notify_list}
            </ul>
            <div className="text-center mb-3">
                <a href="#" className="cs-btn cs-style1">
                    <span>
                    View More
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4366 7.01471C13.7295 6.72181 13.7295 6.24694 13.4366 5.95404L8.66361 1.18107C8.37072 0.888181 7.89584 0.888181 7.60295 1.18107C7.31006 1.47397 7.31006 1.94884 7.60295 2.24173L11.8456 6.48438L7.60295 10.727C7.31006 11.0199 7.31006 11.4948 7.60295 11.7877C7.89584 12.0806 8.37072 12.0806 8.66361 11.7877L13.4366 7.01471ZM0.90625 7.23438H12.9062V5.73438H0.90625V7.23438Z" fill="currentColor"/>
                    </svg> 
                    </span>                         
                </a>
            </div>
        </div>
        </Tooltip>
      );
    return (
        <>
            <header className="header">
                <Navbar collapseOnSelect expand="lg" id='mynav'  className='nav overlay fixed-top  head' variant='dark'>
                    <div className="container">
                        <Navbar.Brand  to="/"><img src={'images/smart1-01.png'} height="59.06px" width="50px" alt='AFRICANSTACK' /></Navbar.Brand> 
                            <span className='small ml-auto mr-2'>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                    >
                                    <div className="cs-toggle_btn cs-header_icon_btn cs-center">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 6.63916C14 5.44569 13.5259 4.30109 12.682 3.45718C11.8381 2.61327 10.6935 2.13916 9.5 2.13916C8.30653 2.13916 7.16193 2.61327 6.31802 3.45718C5.47411 4.30109 5 5.44569 5 6.63916C5 11.8892 2.75 13.3892 2.75 13.3892H16.25C16.25 13.3892 14 11.8892 14 6.63916Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.7981 16.3887C10.6663 16.616 10.477 16.8047 10.2493 16.9358C10.0216 17.067 9.76341 17.136 9.50063 17.136C9.23784 17.136 8.97967 17.067 8.75196 16.9358C8.52424 16.8047 8.33498 16.616 8.20312 16.3887" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>                  
                                        <span className="cs-btn_badge"> {notify.length} </span>
                                    </div>
                                </OverlayTrigger>
                            </span>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} 
                            className='toggle'
                            onClick={openNav}>
                            &#9776;
                            </Navbar.Toggle>
                            <Navbar.Collapse id="responsive-navbar-nav" in={open}>
                            <Link to='#' className="closebtn d-md-none mt-1 mb-3" onClick={closeNav}>&times;</Link>
                            <Nav className='align-self-center ml-auto'>
                                <NavItem>
                                    <a id='home' className="text-white nav-link  mt-3  ml-3 first" href='/'
                                    > Home</a>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link  mt-3 ml-3" to='/' 
                                    > About Us </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link  mt-3 ml-3 text-white" to='/explore'
                                    > Market</NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink className="nav-link  mt-2 ml-3" to='/'
                                    > Plans</NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink className="nav-link  mt-3 ml-3" to='/'
                                    >Contact</NavLink>
                                </NavItem>
                                    
                                        {user ? (
                                            <>
                                                <NavItem>
                                                    <NavLink className="big nav-link mt-2 ml-3 text-white" style={{background: 'none'}} to='#'> 
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                            >
                                                            <div className="cs-toggle_btn cs-header_icon_btn cs-center">
                                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14 6.63916C14 5.44569 13.5259 4.30109 12.682 3.45718C11.8381 2.61327 10.6935 2.13916 9.5 2.13916C8.30653 2.13916 7.16193 2.61327 6.31802 3.45718C5.47411 4.30109 5 5.44569 5 6.63916C5 11.8892 2.75 13.3892 2.75 13.3892H16.25C16.25 13.3892 14 11.8892 14 6.63916Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M10.7981 16.3887C10.6663 16.616 10.477 16.8047 10.2493 16.9358C10.0216 17.067 9.76341 17.136 9.50063 17.136C9.23784 17.136 8.97967 17.067 8.75196 16.9358C8.52424 16.8047 8.33498 16.616 8.20312 16.3887" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>                  
                                                                <span className="cs-btn_badge"> {notify.length} </span>
                                                            </div>
                                                        </OverlayTrigger>
                                                        
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className="nav-link ml-3 mt-2"   to='#' 
                                                    > 
                                                        <>
                                                            <NavDropdown title={<span className='mr-1'>{name.split(" ")[0]}</span>} id="basic-nav-dropdown">
                                                                <NavDropdown.Item >
                                                                    <Link to='/dashboard' onClick={reloadPage}>
                                                                    My Profile
                                                                    </Link>
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Divider />
                                                                <NavDropdown.Item href='#' onClick = {handleLogout}>
                                                                    Logout
                                                                </NavDropdown.Item>
                                                            </NavDropdown>
                                                            {/* <div className="dropdown">
                                                                <button className="btn dropdown-toggle" type="button" id="basic-nav-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <span className='mr-1'>{name.split(" ")[0]}</span>
                                                                </button> 
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" href="/dashboard">Dashboard</a></li>
                                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                </ul>
                                                            </div> */}
                                                        </>
                                                    </NavLink>
                                                </NavItem>
                                            </>
                                        ):
                                        <>
                                        
                                            <NavItem>
                                                <NavLink className="nav-link mt-3 ml-3 text-white"  to='/login' > 
                                                    Login
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className="nav-link ml-3 "  to='/user_type' 
                                                > 
                                                    <button className='btn btn-lg ' id="basic-nav-dropdown">Sign Up</button>
                                                </NavLink>
                                            </NavItem>
                                        </>
                                        }
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </header>
            <div className="cs-height_90 cs-height_lg_80"></div>
        
        
        </>
    );
}

export default Header;