import React, {useState, useEffect, FC} from 'react';
import {Switch, Route, Redirect, Link, useHistory} from 'react-router-dom';
import FourZeroThree from '../../utils/FourZeroThree';
import NavbarAdmin from '../admin/Navbar';
import AdminSubscription from '../admin/pages/AdminSubscription';
import AllUser from '../admin/pages/AllUser';
import Dash from '../admin/pages/Dash';
import PendingWithdrawal from '../admin/pages/PendingWithdrawal';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import Dashboard from '../Dashboard';
import Home from '../Homepage';
import Profile from '../profile';
import TotalWithdrawal from '../admin/pages/TotalWithdrawal';
import CreateAdmin from '../admin/pages/CreateAdmin';
import ReceiveNotification from '../admin/pages/ReceiveNotification';
import Category from '../Auth/Category';
import Header from '../Dashboard/pages/Header';
import Settings from '../Dashboard/pages/Settings';
import MyItems from '../Dashboard/pages/MyItems';
import Create from '../Dashboard/pages/Create';
import AdminSignup from '../admin/auth/AdminSignup';
import AppCategory from '../admin/pages/AppCategory';
import AdminAccountDetails from '../admin/pages/AdminAccountDetails';
import AllAppCategory from '../admin/pages/AllAppCategory';
import AdminLogin from '../admin/auth/AdminLogin';
import axios from 'axios';
import Explorer from '../Explorer';
import MyPurchase from '../Dashboard/pages/MyPurchase';



export default function MainComponent() {
    const [allItem, setAllItem] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory()
    
    useEffect(()=>{
        const base_url = process.env.REACT_APP_SERVER;
        // checkIsLoggedIn()
        async function myAPI(){
            try {
                const res = await axios.get(`${base_url}/api/allitems`)
                setAllItem(res.data.payload);
            } catch (error) {
                switch (error.response.status){
                    case 403:
                        History.push('/403')
                    default:
                        console.log(error)
                }
            }
        }
        myAPI()
    }, []);  
    function PrivateRoute({ component: Component, ...rest }) {
        const auth = localStorage.getItem('africanStack')
        return (
            <Route {...rest} render={props => {
                if (!auth) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }
    
                // authorized so return component
                return <Component {...props} />
            }} />
        );
    }
    return (
        <div>
            <Switch>
                <Route exact path='/'> 
                    <Home data = {allItem}/>
                </Route>
                <Route exact path='/user_type'> 
                    <Category/>
                </Route>
                <Route exact path='/signup'> 
                    <Signup/>
                </Route>
                <Route exact path='/login'> 
                    <Login/>
                </Route>
                {/* <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/mypurchase" component={MyPurchase} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute exact path="/create" component={Create} />
                <PrivateRoute exact path="/create" component={Profile} /> */}
                <Route exact path='/dashboard'> 
                   <Dashboard/>
                </Route>
                <Route exact path='/mypurchase'> 
                    <MyPurchase/>
                </Route>
                <Route exact path='/explore'> 
                    <Explorer data={allItem}/>
                </Route>
                <Route exact path='/settings'> 
                    <Settings/>
                </Route>
                <Route exact path='/my_items'> 
                    <MyItems />
                </Route>
                <Route exact path='/create'> 
                    <Create/>
                </Route>
                <Route exact path='/profile'> 
                    <Profile/>
                </Route>

                {/* Admin */}
                <Route path='/admin/dashboard'>
                    <NavbarAdmin/>
                    <Dash/>
                </Route>
                <Route path='/admin/login'>
                    <AdminLogin/>
                </Route>
                <Route path='/admin/user/all'>
                    <NavbarAdmin/>
                    <AllUser/>
                </Route>
                <Route path='/admin/subscription/all'>
                    <NavbarAdmin/>
                    <AdminSubscription/>
                </Route>
                <Route path='/admin/bank_details'>
                    <NavbarAdmin/>
                    <AdminAccountDetails/>
                </Route>
                <Route path='/admin/appcategory/new'>
                    <NavbarAdmin/>
                    <AppCategory/>
                </Route>
                <Route path='/admin/appcategory/all'>
                    <NavbarAdmin/>
                    <AllAppCategory/>
                </Route>
                <Route path='/admin/pendingfund'>
                    <NavbarAdmin/>
                    <PendingWithdrawal/>
                </Route>
                <Route path='/adminsignup'>
                    <AdminSignup/>
                </Route>
                <Route path='/admin/create_admin'>
                    <NavbarAdmin/>
                    <CreateAdmin/>
                </Route>
                <Route path='/admin/pay/history'>
                    <NavbarAdmin/>
                    <TotalWithdrawal/>
                </Route>
                <Route path='/admin/incoming/notification'>
                    <NavbarAdmin/>
                    <ReceiveNotification/>
                </Route>
                <Route path='/403'>
                    <FourZeroThree/>
                </Route>
                <Redirect to='/'/>
            </Switch>
        </div>
    )
}