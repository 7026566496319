import React from 'react';
import Typical from 'react-typical';
function SectionOne(props) {
    return (
        <section class="cs-hero cs-style4 cs-bg cs-center" data-src="images/hero_bg4.jpeg">
    <div class="container-fluid">
      <div class="cs-hero_in">
        <div class="cs-hero_in_left">
          <div class="cs-hero_text">
            <h1 class="cs-hero_title cs-white_color">The Best Beneficial Place to Buy and Sell Software Marketplace</h1>
            <div class="cs-hero_subtitle cs-medium cs-white_color">Secure software solutions ranging from web app, mobile app, desktop app and hosting servers <br/>with a buy now pay later service</div>
            <div class="cs-hero_btns">
              <a href="explore-1.html" class="cs-hero_btn cs-style1 cs-color3"><span>Explore</span></a>
              <a href="create-items.html" class="cs-hero_btn cs-style1 cs-color1"><span>Create</span></a>
            </div>
          </div>
        </div>
        <div class="cs-hero_in_right">
          <div class="cs-slider cs-style1">
            <div class="cs-slider_container" data-autoplay="0" data-loop="1" data-speed="600" data-center="1" data-slides-per-view="responsive" data-xs-slides="1" data-sm-slides="3" data-md-slides="3" data-lg-slides="3" data-add-slides="3">
              <div class="cs-slider_wrapper">
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      2.1K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_23.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_12.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Beautiful shiba artwork</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.29 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      3.3K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_24.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_13.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Cool octopus traveling</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.63 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      5.3K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_25.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_14.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Diamond animals</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.27 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      2.1K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_23.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_12.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Beautiful shiba artwork</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.29 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      3.3K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_24.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_13.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Cool octopus traveling</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.63 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cs-slide">
                  <div class="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span class="cs-card_like cs-primary_color">
                      <i class="fas fa-heart fa-fw"></i>
                      5.3K
                    </span>
                    <a href="#" class="cs-card_thumb cs-zoom_effect">
                      <img src="images/general/general_25.jpeg" alt="Image" class="cs-zoom_item"/>
                    </a>
                    <div class="cs-card_info">
                      <a href="#" class="cs-avatar cs-white_bg">
                        <img src="images/avatar/avatar_14.png" alt="Avatar"/>
                        <span>Johny E.</span>
                      </a>
                      <h3 class="cs-card_title"><a href="#">Diamond animals</a></h3>
                      <div class="cs-card_price">Current Bid: <b class="cs-primary_color">0.27 ETH 7/21</b></div>
                      <hr/>
                      <div class="cs-card_footer">
                        <span class="cs-card_btn_1" data-modal="#history_1">
                          <i class="fas fa-redo fa-fw"></i>
                          View History
                        </span>
                        <span class="cs-card_btn_2" data-modal="#bid_1"><span>Place Bid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cs-slider_arrows cs-style1 cs-center cs-hidden_mobile">
              <div class="cs-left_arrow cs-center cs-box_shadow">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0269 7.55957H0.817552" stroke="currentColor" stroke-width="1.16474" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.92188 1.45508L0.817222 7.55973L6.92188 13.6644" stroke="currentColor" stroke-width="1.16474" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                                   
              </div>
              <div class="cs-right_arrow cs-center cs-box_shadow">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.816895 7.55957H13.0262" stroke="currentColor" stroke-width="1.16474" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.92188 1.45508L13.0265 7.55973L6.92188 13.6644" stroke="currentColor" stroke-width="1.16474" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                  
              </div>
            </div>
            <div class="cs-pagination cs-style1 cs-hidden"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
    );
}

export default SectionOne;