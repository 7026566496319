import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline", background: "lightblue" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "lightblue" }}
        onClick={onClick}
      />
    );
  }

  
function SectionFour(props) {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
    const settingsTwo = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToScroll: 1,
      };
    return (
        <>
            <div className="cs-height_95 cs-height_lg_70"></div> 
            <section>
                <div className="container-fluid">
                    <h2 className="cs-section_heading cs-style1 text-center">Explore By Catagory</h2>
                    <div className="cs-height_45 cs-height_lg_45"></div>
                        <div className="cs-slider cs-style1 cs-gap-30">
                            <div className="cs-slider_container" data-autoplay="1" data-loop="1" data-speed="600" data-center="0" data-slides-per-view="responsive" data-xs-slides="2" data-sm-slides="3" data-md-slides="4" data-lg-slides="5" data-add-slides="5">
                            <div className="cs-slider_wrapper">
                                <div className="cs-slide">
                                    <Link to= {{pathname:'/explore', state:{type: 'Web application'}}}
                                    className="cs-card cs-style6 cs-box_shadow cs-white_bg text-center">
                                        <span className="cs-avatar"><img src="images/avatar/avatar_39.png" alt="Avatar"/></span>
                                        <div className="cs-card_info">
                                        <h3 className="cs-card_title">Web App</h3>
                                        <div className="cs-card_subtitle">Exercitation veniam</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="cs-slide">
                                <Link to= {{pathname:'/explore', state:{type: 'Mobile application'}}}
                                className="cs-card cs-style6 cs-box_shadow cs-white_bg text-center">
                                    <span className="cs-avatar"><img src="images/avatar/avatar_40.png" alt="Avatar"/></span>
                                    <div className="cs-card_info">
                                    <h3 className="cs-card_title">Mobile App</h3>
                                    <div className="cs-card_subtitle">Exercitation veniam</div>
                                    </div>
                                </Link>
                                </div>
                                <div className="cs-slide">
                                <Link to= {{pathname:'/explore', state:{type: 'Desktop application'}}}
                                className="cs-card cs-style6 cs-box_shadow cs-white_bg text-center">
                                    <span className="cs-avatar"><img src="images/avatar/avatar_41.png" alt="Avatar"/></span>
                                    <div className="cs-card_info">
                                    <h3 className="cs-card_title">Desktop App</h3>
                                    <div className="cs-card_subtitle">Exercitation veniam</div>
                                    </div>
                                </Link>
                                </div>
                                <div className="cs-slide">
                                    <Link to= {{pathname:'/explore', state:{type: 'Hosting server'}}} 
                                    className="cs-card cs-style6 cs-box_shadow cs-white_bg text-center">
                                        <span className="cs-avatar"><img src="images/avatar/avatar_42.png" alt="Avatar"/></span>
                                        <div className="cs-card_info">
                                        <h3 className="cs-card_title">Hosting Server</h3>
                                        <div className="cs-card_subtitle">Exercitation veniam</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                              <div className="cs-height_10 cs-height_lg_10"></div>
                              <div className="cs-pagination cs-style1"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SectionFour;