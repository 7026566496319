import React from 'react';
import Header from './pages/Header';
import SectionFive from './pages/SectionFive';
import SectionFour from './pages/SectionFour';
import SectionOne from './pages/SectionOne';
import SectionSix from './pages/SectionSix';
import SectionThree from './pages/SectionThree';
import SectionTwo from './pages/SectionTwo';

function Home({data}) {
    return (
        <div>
            <Header/>
            <SectionOne/>
            <SectionTwo/>
            <SectionThree data= {data}/>
            <SectionFour/>
            <SectionFive/>
            {/* <SectionSix/> */}
        </div>
    );
}

export default Home;