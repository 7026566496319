import React, {useState, useEffect}  from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { acccountGen, activatePlan, generateAcc } from '../../../utils/postData';
import { Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';

function SectionThree({data}) {
    const [modal, setModal]= useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [account, setAccount] = useState('');
    const [state, setState] = useState({
      name: '',
      amount:'',
      duration: '',
      id: ''
    });

    const history = useHistory();

    useEffect(()=>{
      const base_url = process.env.REACT_APP_SERVER;
      async function myAPI(){
          try {
              const res = await axios.get(`${base_url}/api/payment/account_number`)
              setAccount(res.data.payload);
          } catch (error) {
            console.log(error)
          }
      }
      myAPI()
    }, [message]);  
    const toggleModal=(name, duration, amount, id)=>{
        if(amount){
          setState({
            amount,
            name,
            duration,
            id
          })
          setModal(!modal)
        }
        else{
          setModal(!modal)
        }
      }

    const handleChange = (e)=>{
      setState({
          ...state,
          [e.target.name]: e.target.value
      })
    }

    const handleSubscribe = async e =>{
      e.preventDefault();
      setIsLoading(true);
      var data  = {duration: state.duration, id: state.id}
      try{
        await activatePlan(data);
        setMessage("Subscription successful")
        setIsLoading(false);
        window.location.reload()
      }
      catch(err){
        if(err.response){
          console.log(err.response)
        }
        else{
          console.log(err)
        }
      }
    }
    
    const handleSubmit =async e =>{
      e.preventDefault();
      setIsLoading(true);
      if(!localStorage.getItem("africanStack")){
        return history.push('/login')
      }
        var data = {account_name: JSON.parse(localStorage.getItem("africanStack")).name}
        try{
          const res = await acccountGen(data);
          console.log(res.data)
          setMessage("Account Generated")
          setIsLoading(false);
          // window.location.reload()
        }
        catch(err){
          if(err.response){
            console.log(err.response)
          }
          else{
            console.log(err)
          }
        }
      
    }

    const mobile = data.slice(0,8).filter(d=> d.category === 'Mobile application').map(d=>{
        return(
            <div className="col-md-3 col-sm-6" key ={d._id}>
            <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
              {/* <span className="cs-card_like cs-primary_color">
                <i className="fas fa-heart fa-fw"></i>
                2.1K
              </span> */}
              <div  className="cs-card_thumb cs-zoom_effect">
                <img src={d.avatar} alt="Image" className="cs-zoom_item"/>
              </div>
              <div className="cs-card_info">
                <a href="#" className="cs-avatar cs-white_bg">
                  {d.user_pic ? <img src={d.user_pic} alt="Avatar"/> :<img src="images/avatar/avatar_12.png" alt="Avatar"/>}
                  <span>{d.name.split(" ")[0]}</span>
                </a>
                <h3 className="cs-card_title">{d.project_name}</h3>
                <div className="cs-card_price">Price: <b className="cs-primary_color">&#8358;{d.amount}</b></div>
                <small>Payment duration: <br/>{d.duration}</small>
                <hr/>
                <div className="cs-card_footer">
                  <span className="cs-card_btn_1" data-modal="#history_1">
                    <a href={d.demo_link} target='_blank'>demo link</a>
                  </span>
                  <span className="cs-card_btn_2" data-modal="#bid_1" aria-disabled onClick={() =>toggleModal(d.project_name, d.duration, d.amount, d._id)}><span>Place Bid</span></span>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
          </div>
        )
    })


    const web = data.slice(0,8).filter(d=> d.category === 'Web application').map(d=>{
        return(
            <div className="col-md-3 col-sm-6" key ={d._id}>
            <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
              {/* <span className="cs-card_like cs-primary_color">
                <i className="fas fa-heart fa-fw"></i>
                2.1K
              </span> */}
              <div  className="cs-card_thumb cs-zoom_effect">
                <img src={d.avatar} alt="Image" className="cs-zoom_item"/>
              </div>
              <div className="cs-card_info">
                <a href="#" className="cs-avatar cs-white_bg">
                  {d.user_pic ? <img src={d.user_pic} alt="Avatar"/> :<img src="images/avatar/avatar_12.png" alt="Avatar"/>}
                  <span>{d.name.split(" ")[0]}</span>
                </a>
                <h3 className="cs-card_title">{d.project_name}</h3>
                <div className="cs-card_price">Price: <b className="cs-primary_color">&#8358;{d.amount}</b></div>
                <small>Payment duration: <br/>{d.duration}</small>
                <hr/>
                <div className="cs-card_footer">
                  <span className="cs-card_btn_1" data-modal="#history_1">
                    <a href={d.demo_link} target='_blank'>demo link</a>
                  </span>
                  <span className="cs-card_btn_2" data-modal="#bid_1" aria-disabled onClick={() =>toggleModal(d.project_name, d.duration, d.amount, d._id)}><span>Place Bid</span></span>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
          </div>
        )
    })
    
    const desktop = data.slice(0,8).filter(d=> d.category === 'Desktop application').map(d=>{
        return(
            <div className="col-md-3 col-sm-6" key ={d._id}>
            <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
              {/* <span className="cs-card_like cs-primary_color">
                <i className="fas fa-heart fa-fw"></i>
                2.1K
              </span> */}
              <div  className="cs-card_thumb cs-zoom_effect">
                <img src={d.avatar} alt="Image" className="cs-zoom_item"/>
              </div>
              <div className="cs-card_info">
                <a href="#" className="cs-avatar cs-white_bg">
                  {d.user_pic ? <img src={d.user_pic} alt="Avatar"/> :<img src="images/avatar/avatar_12.png" alt="Avatar"/>}
                  <span>{d.name.split(" ")[0]}</span>
                </a>
                <h3 className="cs-card_title">{d.project_name}</h3>
                <div className="cs-card_price">Price: <b className="cs-primary_color">&#8358;{d.amount}</b></div>
                <small>Payment duration: <br/>{d.duration}</small>
                <hr/>
                <div className="cs-card_footer">
                  <span className="cs-card_btn_1" data-modal="#history_1">
                    <a href={d.demo_link} target='_blank'>demo link</a>
                  </span>
                  <span className="cs-card_btn_2" data-modal="#bid_1" aria-disabled onClick={() =>toggleModal(d.project_name, d.duration, d.amount, d._id)}><span>Place Bid</span></span>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
          </div>
        )
    })


    const all = data.slice(0,8).map(d=>{
        return(
            <div className="col-md-3 col-sm-6" key ={d._id}>
            <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
              {/* <span className="cs-card_like cs-primary_color">
                <i className="fas fa-heart fa-fw"></i>
                2.1K
              </span> */}
              <div  className="cs-card_thumb cs-zoom_effect">
                <img src={d.avatar} alt="Image" className="cs-zoom_item"/>
              </div>
              <div className="cs-card_info">
                <a href="#" className="cs-avatar cs-white_bg">
                  {d.user_pic ? <img src={d.user_pic} alt="Avatar"/> :<img src="images/avatar/avatar_12.png" alt="Avatar"/>}
                  <span>{d.name.split(" ")[0]}</span>
                </a>
                <h3 className="cs-card_title">{d.project_name}</h3>
                <div className="cs-card_price">Price: <b className="cs-primary_color">&#8358;{d.amount}</b></div>
                <small>Payment duration: <br/>{d.duration}</small>
                <hr/>
                <div className="cs-card_footer">
                  <span className="cs-card_btn_1" data-modal="#history_1">
                    <a href={d.demo_link} target='_blank'>demo link</a>
                  </span>
                  <span className="cs-card_btn_2" data-modal="#bid_1" aria-disabled onClick={() =>toggleModal(d.project_name, d.duration, d.amount, d._id)}><span>Place Bid</span></span>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
          </div>
        )
    })

    return (
        <>
            <div className="cs-height_70 cs-height_lg_40"></div>
            <section>
                <div className="container-fluid">
                    <div className="cs-section_heading cs-style2">
                        <div className="cs-section_left">
                          <h2 className="cs-section_title">New Items</h2>
                        </div>
                        <div className="cs-section_right">
                          <a href="explore-1.html" className="cs-btn cs-style1"><Link to='explore'> <span className='text-white'>Explore More</span></Link></a>
                        </div>
                    </div>
                    <div>
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                          <div className='row' >
                            <div className='col-12 mx-auto col-md-5 mt-4 mb-3'
                            style={{background: "linear-gradient(116.85deg, rgba(252, 70, 107, 0.2) 0%, rgba(63, 94, 251, 0.2) 100%)",
                            borderRadius:"23px"}}>
                              <Nav variant="pills pt-1 pb-1 text-center" >
                                <Nav.Item> 
                                  <Nav.Link eventKey="first">All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">Mobile App</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="third">Web App</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="forth">Desktop App</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="fifth" className='server'>Hosting Server</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>
                            <div className='col-12 mt-2'>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <div className='row'>
                                    {all}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  <div className='row'>
                                    {mobile}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                  <div className='row'>
                                    {web}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="forth">
                                  <div className='row'>
                                    {desktop}
                                  </div>
                                </Tab.Pane>
                                
                              </Tab.Content>
                            </div>
                          </div>
                      </Tab.Container>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12">
                    <Modal isOpen= {modal} toggle={toggleModal}>
                      <ModalHeader  toggle={() => toggleModal()}>
                          <h4>Subscribe</h4>
                      </ModalHeader>
                        <ModalBody>
                            <form   className="mt-4 " >
                                  <div className='container'>
                                    <div className='mt-2'>
                                      {message && <Alert  variant={'success'} className='pb-3 text-center'>
                                                              {message}
                                                          </Alert>}
                                      </div>
                                    <div className='row mb-2'>
                                        <div className='col-6'>
                                          Project Name
                                        </div>
                                        <div className='col-6 text-right'>
                                          {state.name}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-6'>
                                          Current Price
                                        </div>
                                        <div className='col-6 text-right'>
                                          &#8358;{state.amount}
                                        </div>
                                    </div>
                                    <div className='row mb-5'>
                                        <div className='col-6 '>
                                          Payment Duration
                                        </div>
                                        <div className='col-6 text-right'>
                                          {state.duration}
                                        </div>
                                    </div>
                                    <div className='row mb-1'>
                                        <div className='col-12'>
                                         <p>Choose to make an installmental payment and pay up within {state.duration}</p>
                                        </div>
                                    </div>
                                    {account ?
                                      <>
                                        <div className='row'>
                                            <div className='col-6 '>
                                              Account Number
                                            </div>
                                            <div className='col-6 text-right'>
                                              {account}
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-6 '>
                                              Bank
                                            </div>
                                            <div className='col-6 text-right'>
                                              Providus
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <button className='btn btn-lg 
                                            mt-2 btn-primary w-100'
                                            onClick={handleSubscribe}>
                                              {isLoading ? <Spinner variant='light' 
                                              animation="border" 
                                              role="status"/>: <>Subscribe</>}
                                            </button>
                                        </div>
                                      </> 
                                     : 
                                        <div className='row'>
                                            <button className='btn btn-lg btn-primary w-100'
                                            type='submit' onClick={handleSubmit}>
                                              {isLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Generate Payment Account</>}
                                            </button>
                                        </div>
                                     }
                                  </div>
                                </form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default SectionThree;